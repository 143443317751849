app-root {
  display: contents;
  main-layout {
    width: 100%;
    min-height: 100%;
    display: block;
  }
}
#cttMain {
  padding: 1rem 0;
  width: 100%;
  margin: 0;
  min-height: 30vh;
}
.content_scroll-site {
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr max-content;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  &.not__scroll {
    grid-template-rows: minmax(0, 1fr) minmax(0, max-content);
  }
}
.container_cy {
  width: 1440px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}
&.swal2-height-auto {
  height: 100% !important;
}
.file-drop-content {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 14px;
  border-style: dashed;
  border-width: medium;
  transition: all ease-in-out 0.26s;
  &:hover {
    background-color: #f7f7f7;
  }
  &::before {
    content: "";
    display: block;
    padding-bottom: 60%;
  }
  &.type_2::before {
    padding-bottom: 38%;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }
  .description-file-after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    height: auto;
    max-width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-flow: column;
    span {
      display: block;
      text-align: center;
      font-size: 14px;
      i {
        font-size: 82px;
      }
    }
  }
}
.content_file {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  span {
    display: block;
    padding: 1rem 1.5rem;
    background-color: #f5f5f5;
    border-radius: 8px;
    width: auto;
    max-width: 100%;
    cursor: default;
    font-size: 15px;
    font-weight: 500 !important;
    color: #5754a3 !important;
  }
  .content_options {
    display: inline-flex;
    gap: 1rem;
    flex-flow: wrap;
    margin-top: 1rem;
    button {
      appearance: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px;
      border: 0;
      border-radius: 6px;
      font-size: 16px;
      &.file-btn_clear {
        background: #e76363;
        color: #fff;
        &:hover {
          background: #d53c3c;
        }
      }
      &.file-btn_submit {
        background: #3dc65f;
        color: #fff;
        &:hover:not(:disabled) {
          background: #2cb14d;
        }
        &:disabled {
          background-color: #b5b5c3;
        }
      }
    }
  }
}
.content__scroll-sidebar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.toast-right-top,
.toast-left-top,
.toast-right-bottom,
.toast-left-bottom {
  height: auto;
}
.swal2-container {
  .swal2-popup {
    .swal2-icon {
      /* width: 4em; height: 4em; */
      .swal2-icon-content {
        font-size: 2.75em;
      }
    }
    .swal2-title {
      font-size: 22px;
    }
    .swal2-html-container {
      font-size: 16px;
    }
    .swal2-actions {
      .swal2-cancel {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
      }
      .swal2-confirm {
        background-color: var(--primary-color);
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        &:focus {
          box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.5);
        }
      }
      .swal2-styled {
        font-size: 16px;
      }
    }
  }
}
table:not(.custom-table-design):not(.mat-calendar-table) {
  border-radius: 12px;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  &.shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);
  }
  thead th {
    border: 0;
    background-color: #fff;
    color: #000;
    font-weight: 600;
    font-size: 15px;
  }
  tbody tr {
    &:not(.no-border) {
      position: relative;
      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          width: 100%;
          height: 1px;
          background-color: rgb(241, 241, 241);
        }
      }
    }
    td {
      font-size: 14px;
      padding-block: 4px;
    }
  }
}
.hide__menu-desktop {
  transition: all ease-in-out 0.5s;
}
.text-two-hidden {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
template-1,
template-2,
template-3,
template-4,
template-5,
app-sidebar,
app-sidebar-documentation,
button-cy {
  display: contents;
}
.sidebar_left {
  &.active {
    transform: translateX(0);
  }
}
.btn_a-sidebar {
  span {
    color: var(--second-color);
    font-weight: 300;
  }
  &.active {
    background-color: var(--second-color);
    i {
      color: var(--primary-color);
    }
    span {
      color: #fff;
      font-weight: 400;
    }
    &::before {
      display: none;
    }
  }
}
app-dynamic-form-field {
  display: contents;
}

/*  ** Material Angular ** */
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: transparent;
}
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.02;
}
.mat-mdc-tooltip-panel {
  mat-tooltip-component {
    font-size: 13px;
  }
  &.mat-mdc-tooltip-panel-below {
    .mdc-tooltip {
      overflow: unset;
      &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent
          var(--mdc-plain-tooltip-container-color) transparent;
      }
    }
  }
  &.mat-mdc-tooltip-panel-above {
    .mdc-tooltip {
      overflow: unset;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: var(--mdc-plain-tooltip-container-color) transparent
          transparent transparent;
      }
    }
  }
  &.mat-mdc-tooltip-panel-left {
    .mdc-tooltip {
      overflow: unset;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent
          var(--mdc-plain-tooltip-container-color);
      }
    }
  }
  &.mat-mdc-tooltip-panel-right {
    .mdc-tooltip {
      overflow: unset;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent var(--mdc-plain-tooltip-container-color)
          transparent transparent;
      }
    }
  }
}
mat-header-cell {
  color: var(--second-color);
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0px !important;
  padding-bottom: 8px;
}
.mat-expansion-indicator::after {
  border-color: #101828;
}
.mat-expansion-panel-content {
  background-color: #f8fafc;
  font-family: var(--font-subtext);
}

mat-expansion-panel-header.btn_a-sidebar {
  font-family: var(--font-subtext);
  height: auto;
  .mat-content {
    display: grid;
    grid-template-columns: minmax(0, 35px) minmax(0, 1fr) minmax(0, 35px);
    gap: 0.5rem;
  }
}
mat-option {
  --mat-option-selected-state-label-text-color: var(--second-color);
}
.mat-menu-content {
  padding: 0;
}
.mat-dialog-container {
  padding: 0;
  border-radius: 22px;
  .header_modal {
    padding: 1rem 1.5rem;
  }
  .content_modal {
    padding: 2rem 2.5rem;
    .content__wrapper {
      h5 {
        display: block;
        line-height: normal;
        width: 100%;
        margin: 0 0 1rem;
        font-size: 16px;
        strong {
          color: var(--primary-color);
          font-weight: 700;
        }
        span {
          color: var(--second-color);
          font-weight: 600;
        }
      }
      ul {
        padding-left: 2rem;
        list-style: none;
        display: grid;
        grid-template-columns: 100%;
        gap: 1rem;
        li {
          width: 100%;
          display: block;
          font-size: 14px;
          strong {
            margin-right: 5px;
          }
        }
      }
      &.note {
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          a {
            color: var(--third-color);
            cursor: pointer;
            font-weight: 600;
            &:hover {
              color: var(--second-color);
            }
          }
        }
      }
    }
  }
  .actions_modal {
    padding: 1rem 1.5rem;
  }
}
mat-dialog-container {
  .mat-mdc-dialog-title {
    h3 {
      color: var(--second-color);
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      border-bottom: 1px solid #d1d5db;
    }
  }
}
mat-calendar {
  --mat-datepicker-calendar-text-size: 12px;
}
.input__range {
  .mat-slider.mat-accent .mat-slider-track-fill,
  .mat-slider.mat-accent .mat-slider-thumb,
  .mat-slider.mat-accent .mat-slider-thumb-label {
    background-color: var(--primary-color);
  }
  mat-slider {
    width: 100%;
    padding: 0;
    height: 24px;
    .mat-slider-wrapper {
      top: 50%;
      transform: translateY(-50%);
      .mat-slider-thumb-label {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.6;
        .mat-slider-thumb-label-text {
          display: inline-block;
        }
      }
    }
  }
}
mat-stepper {
  &.stepper_not-step-header .mat-horizontal-stepper-header-container {
    display: none;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
  }
  .mat-horizontal-stepper-wrapper {
    width: 100%;
  }
  &.stepper_not-change-header mat-step-header.mat-horizontal-stepper-header {
    pointer-events: none !important;
  }
  &.stepper_not-space-content {
    .mat-horizontal-content-container {
      padding: 0;
    }
  }
}
.mat__type-2 {
  .mat-expansion-panel-content {
    background: transparent;
  }
}
.mat__check-option-custom {
  .icon__multiple-check {
    order: 1;
    margin-right: 0;
    margin-left: 16px;
  }
  mat-pseudo-checkbox {
    display: none;
  }
}
/* ** End Material Angular ** */

.checkbox__field {
  width: auto;
  display: inline-block;
  position: relative;
  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    display: block !important;
    appearance: none;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    &:checked ~ span {
      background-color: var(--primary-color, #000);
      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(10deg) scale(1);
      }
    }
  }
  span {
    width: 25px;
    height: 25px;
    display: block;
    border-radius: 0.4rem;
    background-color: #f1f1f1;
    transition: all ease-in-out 0.25s;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transform-origin: center;
      background-color: var(--primary-color, #000);
      border-radius: 4px;
      transition: all ease-in-out 0.2s;
      width: 100%;
      height: 100%;
    }
    &::after {
      content: "\2713";
      font-size: 16px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(10deg) scale(0);
      transform-origin: center;
      transition: all ease-in-out 0.2s;
    }
  }
}
.radio__field {
  width: auto;
  display: inline-block;
  position: relative;
  input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    appearance: none;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    &:checked ~ span {
      background-color: var(--primary-color, #000);
      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(10deg) scale(1);
      }
    }
  }
  &.type__small {
    span {
      width: 20px;
      height: 20px;
    }
  }
  span {
    width: 25px;
    height: 25px;
    display: block;
    border-radius: 50%;
    background-color: #f1f1f1;
    transition: all ease-in-out 0.25s;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transform-origin: center;
      background-color: var(--primary-color, #000);
      border-radius: 4px;
      transition: all ease-in-out 0.2s;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(10deg) scale(0);
      transform-origin: center;
      transition: all ease-in-out 0.2s;
    }
  }
}
.switch__field {
  width: auto;
  display: inline-block;
  position: relative;
  &.switch__option {
    span {
      background-color: var(--second-color);
    }
    &:checked ~ span {
      background-color: var(--primary-color, #000);
    }
  }
  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    appearance: none;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    &:checked ~ span {
      background-color: var(--primary-color, #000);
      &::after {
        -webkit-transform: translateX(0%) translateY(-50%);
        -ms-transform: translateX(0%) translateY(-50%);
        transform: translateX(0%) translateY(-50%);
      }
    }
  }
  span {
    width: 55px;
    height: 30px;
    display: block;
    border-radius: 4rem;
    background-color: #f1f1f1;
    transition: all ease-in-out 0.25s;
    overflow: hidden;
    &::after {
      content: "";
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-100%, -50%);
      transform-origin: center;
      transition: all ease-in-out 0.2s;
      box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.14);
    }
  }
}

.ngx-json-viewer .segment .segment-main .segment-key {
  color: var(--third-color) !important;
}
.ngx-json-viewer .segment-type-string > .segment-main > .segment-value {
  color: #d06909 !important;
}
.ngx-json-viewer .segment-type-null > .segment-main > .segment-value {
  padding: 1px 5px;
  border-radius: 4px;
  background-color: #944ffd !important;
}
.ngx-json-viewer .segment-type-undefined > .segment-main > .segment-key {
  color: #999 !important;
}
.ngx-json-viewer .segment-type-undefined > .segment-main > .segment-value {
  padding: 1px 5px;
  border-radius: 4px;
}

.scrollbar_css {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(128, 128, 128, 0.34);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--second-color);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--second-color);
  }
  &.min_bar {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.34);
      border-radius: 6px;
    }
  }
}

.required {
  &::after {
    content: " *";
    color: red;
  }
}

.radio__btns {
  display: flex;
  flex-flow: wrap;
  gap: 5px;
  button {
    padding: 6px 15px;
    line-height: normal;
    border-radius: 3rem;
    border: 2px solid transparent;
    color: #000;
    font-size: 14px;
    transition: all ease-in-out 0.25s;
    &.status__new {
      color: #4990eb;
      &.active {
        border-color: #4990eb;
      }
    }
    &.status__convert {
      color: #13d213;
      &.active {
        border-color: #13d213;
      }
    }
    &.status__pending {
      color: #ffc400;
      &.active {
        border-color: #ffc400;
      }
    }
    &.status__cancel {
      color: #eb4949;
      &.active {
        border-color: #eb4949;
      }
    }
  }
  mat-radio-group {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #ddd;
    background-color: #ddd;
    gap: 1px;
    overflow: hidden;
    mat-radio-button {
      flex: 1;
      background-color: #fff;
      font-size: 14px;
      .mdc-form-field {
        width: 100%;
        cursor: pointer;
        .mdc-label {
          padding-right: 10px;
          cursor: pointer;
          line-height: normal;
        }
      }
    }
  }
}
.cy__btn {
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  appearance: button;
  margin: 0 !important;
  border: 1px solid transparent;
  &.btn__fit {
    width: fit-content;
    min-width: auto;
  }
  &.btn__icon-rounded {
    padding: 10px;
    border-radius: 50%;
    width: 38px;
    height: 38px;
  }
  &.mdc-button {
    height: auto;
    .mdc-button__label {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 400;
      font-size: 1rem;
      line-height: normal;
    }
    &.small__btn {
      .mdc-button__label {
        font-size: 14px;
      }
    }
    &.btn__primary {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
      color: #fff;
    }
    &.btn__second {
      border-color: var(--second-color);
      background-color: var(--second-color);
      color: #fff;
    }
    &.btn__third {
      border-color: var(--third-color);
      background-color: var(--third-color);
      color: var(--second-color);
    }
    &.btn__error {
      border-color: $error-color;
      background-color: $error-color;
      color: #fff;
    }
    &.btn__success {
      border-color: $success-color;
      background-color: $success-color;
      color: #fff;
    }
    &.btn__warning {
      border-color: $warning-color;
      background-color: $warning-color;
      color: #fff;
    }
    &.btn__info {
      border-color: $info-color;
      background-color: $info-color;
      color: #fff;
    }
    &.btn__grad {
      border-color: linear-gradient(0deg, #eaecf0, #eaecf0);
      background: linear-gradient(0deg, #eaecf0, #eaecf0);
      color: var(--second-color);
    }
    &:disabled {
      border-color: #c9c9c9 !important;
      background-color: #c9c9c9 !important;
      color: #616161 !important;
    }
    // * outline
    &.btn__outline {
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
      background-color: #fff;
    }
    &.btn__outline-primary {
      border: 2px solid var(--primary-color);
      background-color: transparent;
      color: var(--primary-color);
      .mdc-button__label {
        font-weight: 600;
      }
      &.hover_btn-outline:hover {
        background-color: var(--primary-color);
        color: var(--second-color);
      }
    }
    &.btn__outline-second {
      border: 2px solid var(--second-color);
      background-color: transparent;
      color: var(--second-color);
      .mdc-button__label {
        font-weight: 600;
      }
      &.hover_btn-outline:hover {
        background-color: var(--second-color);
        color: #fff;
      }
    }
    &.btn__outline-third {
      border: 2px solid var(--third-color);
      background-color: transparent;
      color: var(--third-color);
      .mdc-button__label {
        font-weight: 600;
      }
      &.hover_btn-outline:hover {
        background-color: var(--third-color);
        color: #fff;
      }
    }
    //* White
    &.btn__white-primary {
      border: 1px solid white;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
      background-color: white;
      color: var(--primary-color);
      &:hover {
        border-color: var(--primary-color);
        background-color: var(--primary-color);
        color: white;
      }
    }
    &.btn__white-second {
      border: 1px solid white;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
      background-color: white;
      color: var(--second-color);
      &:hover {
        border-color: var(--primary-color);
        background-color: var(--primary-color);
        color: white;
      }
    }
  }
  &:not(.mdc-button) {
    &.small__btn {
      font-size: 14px;
    }
    &.btn__primary {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
      color: #fff;
    }
    &.btn__second {
      border-color: var(--second-color);
      background-color: var(--second-color);
      color: #fff;
    }
    &.btn__third {
      border-color: var(--third-color);
      background-color: var(--third-color);
      color: var(--second-color);
    }
    &.btn__error {
      border-color: $error-color;
      background-color: $error-color;
      color: #fff;
    }
    &.btn__success {
      border-color: $success-color;
      background-color: $success-color;
      color: #fff;
    }
    &.btn__warning {
      border-color: $warning-color;
      background-color: $warning-color;
      color: #fff;
    }
    &.btn__info {
      border-color: $info-color;
      background-color: $info-color;
      color: #fff;
    }
    &.btn__grad {
      border-color: linear-gradient(0deg, #eaecf0, #eaecf0);
      background: linear-gradient(0deg, #eaecf0, #eaecf0);
      color: var(--second-color);
    }
    &:disabled {
      border-color: #c9c9c9 !important;
      background-color: #c9c9c9 !important;
    }
    &.btn__outline {
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
      background-color: #fff;
    }
  }
}
.full__hsize {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, auto) minmax(0, 1fr) minmax(0, auto);
  height: 100%;
  .mdc-dialog__content {
    min-height: 100%;
    max-height: 100%;
  }
}

.error__tg {
  font-size: 12px;
  color: var(--error-color);
}

.animation__item-sending {
  width: 150px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.dropdown_menu_cy-item {
  position: relative;
  .dropdown_menu_cy-action {
    text-transform: uppercase;
    color: var(--second-color);
  }
  &.is_open {
    .dropdown_menu_cy-action {
      color: var(--primary-color);
    }
    .dropdown_menu_cy-content {
      z-index: 1;
    }
  }
  &.is_closed {
    .dropdown_menu_cy-content {
      transition: opacity ease-in-out 0.15s, visibility ease-in-out 0.15s;
      opacity: 0;
      visibility: hidden;
    }
  }
  .dropdown_menu_cy-content {
    position: absolute;
    top: calc(100% + 40px);
    min-width: 300px;
    &.position__center {
      left: 50%;
      transform: translateX(-50%);
    }
    &.position__left {
      left: 0;
    }
    &.position__right {
      right: 0;
    }
    .dropdown_menu_cy-content-int {
      position: relative;
      width: 100%;
      padding: 0.5rem;
      background-color: #fff;
      color: var(--second-color);
      border-radius: 10px;
      box-shadow: 1px -5px 6px 0px rgba(15, 24, 41, 0.031),
        0px 12px 16px -4px rgba(15, 24, 41, 0.078);
      max-height: 500px;
      overflow: auto;
    }
  }
  &.cart__position {
    &:has(.position__center) {
      .dropdown_menu_cy-content.position__center {
        left: calc(50% - 52px);
      }
    }
    &:has(.position__right) {
      .dropdown_menu_cy-content.position__right {
        right: -52px;
      }
    }
  }
}

@keyframes textUp {
  0% {
    top: 120%;
  }
  100% {
    top: 50%;
  }
}

.custom__card-2 {
  padding: 1rem;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  .content__head {
    width: 100%;
    text-align: center;
    .title__card {
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      margin-bottom: 10px;
    }
  }
  .content__desc {
    padding: 1rem 0;
    .text__card {
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }
  .content__btns {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}

.content__payments {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
  border-radius: 12px;
  overflow: hidden;
  .content__header {
    padding: 1rem;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .content__profile {
      background: #f9fafb;
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.051);
      border-radius: 8px;
      padding: 0.8rem 1rem;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      gap: 10px;
      .card__status {
        border: 1px solid #d0d5dd;
        background-color: #fff;
        border-radius: 6px;
        padding: 3px 8px 3px 8px;
        font-size: 12px;
      }
    }
    .content__btns,
    .content__btns_1 {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      gap: 10px;
    }
  }
}

.desc__profile {
  display: flex;
  align-items: center;
  &.type__small {
    display: grid;
    grid-template-columns: minmax(0, 35px) minmax(0, 1fr);
    .profile__img {
      width: 35px;
      height: 35px;
    }
    .profile__name {
      margin-left: 5px;
      font-size: 14px;
      .status {
        font-size: 0.65rem;
        padding: 0.2rem 0.3rem;
      }
    }
  }
  .profile__img {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: scale-down;
      object-position: center;
    }
  }
  .profile__name {
    font-weight: 500;
    font-size: 16px;
    color: #475467;
  }
  .card__subscription {
    width: 100%;
    display: block;
    font-size: 21px;
    font-weight: 700;
    color: var(--primary-color);
    line-height: normal;
  }
}

.status {
  background-color: rgba(2, 7, 71, 0.055);
  color: var(--second-color);
  font-size: 0.75rem;
  line-height: normal;
  padding: 0.4rem 0.9rem;
  border-radius: 5rem;
  width: fit-content;
  max-width: 100%;
  &.status__send,
  &.status__success,
  &.status__paid {
    color: var(--success-color);
    background-color: rgba(var(--rgb-success-color), 0.055);
  }
  &.status__sending,
  &.status__refunded {
    color: var(--info-color);
    background-color: rgba(var(--rgb-info-color), 0.055);
  }
  &.status__cancel,
  &.status__error,
  &.status__closed {
    color: var(--error-color);
    background-color: rgba(var(--rgb-error-color), 0.055);
  }
}

.content__card-section-2 {
  padding: 2rem 1.5rem;
  display: block;
  width: 100%;
  border-radius: 12px;
  background-color: rgba(var(--rgb-second-color), 0.8);
  background-image: url("/assets/img/general/card-section-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  h5 {
    font-size: 18px;
    font-family: var(--font-title);
    font-weight: 400;
    text-transform: uppercase;
    line-height: normal;
    margin: 0;
    margin-bottom: 5px;
    color: #fff;
  }
  p {
    font-size: 15px;
    font-family: var(--font-title);
    font-weight: 300;
    text-transform: uppercase;
    line-height: normal;
    margin-bottom: 10px;
    color: #fff;
  }
  .action__btn {
    font-family: var(--font-title);
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    color: var(--primary-color);
    text-decoration: underline;
    display: inline-block;
    border: 0;
    appearance: none;
    outline: none;
    &:hover {
      color: var(--third-color);
    }
  }
}
.content__card-section-3 {
  padding: 1rem;
  display: block;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  display: flex;
  flex-flow: column;
}

.content__swiper {
  width: 100%;
  padding: 2rem 1rem;
  position: relative;
  .nav__slider {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    color: var(--second-color);
    font-size: 38px;
    background: transparent;
    &.swiper-button-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  .prev__slider {
    left: -1.5rem;
  }
  .next__slider {
    right: -1.5rem;
  }
  .pagination__slider {
    position: relative;
    width: 100%;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 5px;
      border-radius: 50%;
      background-color: var(--second-color);
      opacity: 0.5;
      display: inline-block;
      transition: all ease-in-out 0.2s;
      vertical-align: middle;
      &.swiper-pagination-bullet-active {
        opacity: 1;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.card-general {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem 1.25rem;
  .title__card {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
    padding-bottom: 0.25rem;
    width: max-content;
    display: inline-block;
    margin: 0;
    margin-bottom: 1rem;
    position: relative;
    &::before {
      content: "";
      background-color: var(--primary-color);
      width: 60%;
      height: 2px;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
  .btns__card-account {
    width: 100%;
    display: block;
    text-align: center;
  }
}

// ** Payment Card
.content__payment-card-list {
  padding-top: 1rem;
  .content__payment-card {
    margin-bottom: 1rem;
  }
}
.content__payment-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: var(--color-card-payment-bg, #f3f3f3);
  border-radius: 1rem;
  aspect-ratio: 1/0.55;
  min-height: 180px;
  position: relative;
  transition: all linear 0.5s;
  color: var(--color-card-payment-text, #000);
  &:hover {
    .delete__payment-card {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
  .first__card {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: all linear 0.3s;
    .content__logo-type-card {
      width: 100%;
      text-align: right;
      position: relative;
      z-index: 1;
      .logo__type-card {
        width: 80px;
        height: 40px;
        display: inline-block;
        transition: all linear 0.3s;
        background-color: var(--color-card-payment-bg, #f3f3f3);
        border-radius: 5px;
        svg,
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          position: relative;
        }
      }
    }
    .number__card {
      position: absolute;
      padding: 0.8rem 0;
      font-size: 25px;
      font-weight: 600;
      text-align: left;
      width: 100%;
      line-height: normal;
      transition: all linear 0.3s;
      bottom: 0;
      left: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .detail__card {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, max-content);
    transition: all linear 0.3s;
    .name__card {
      padding-right: 1rem;
      span {
        font-size: 12px;
      }
      p {
        font-size: 17px;
        font-weight: 500;
        transition: all linear 0.3s;
        line-height: normal;
      }
    }
    .date__card {
      span,
      abbr {
        font-size: 12px;
      }
      p {
        font-size: 17px;
        font-weight: 500;
        transition: all linear 0.3s;
        line-height: normal;
      }
    }
  }
  &.card__list,
  &.card__select-list:not(.selected__card) {
    min-height: auto;
    max-height: 75px;
    padding: 0.8rem 1rem;
    border-radius: 0.6rem;
    .first__card {
      .number__card {
        width: calc(100% - 45px);
        font-size: 17px;
        padding: 0.5rem;
      }
      .content__logo-type-card .logo__type-card {
        width: 45px;
        height: 28px;
      }
    }
    .detail__card {
      padding: 0 0.5rem;
      .name__card,
      .date__card {
        span,
        abbr {
          display: none;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  &.card__select-list,
  &.card__normal-select {
    outline: 2px solid transparent;
    cursor: pointer;
    user-select: none;
    &:hover,
    &.selected__card {
      outline-color: var(--primary-color);
    }
    &.selected__card {
      cursor: auto;
    }
  }
  &.card__normal-select {
    .first__card .number__card {
      font-size: 20px;
    }
  }
}

.payment-card-add {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: var(--color-card-payment-bg, #f3f3f3);
  border-radius: 1rem;
  aspect-ratio: 1/0.55;
  min-height: 180px;
  position: relative;
  transition: all linear 0.5s;
  color: var(--color-card-payment-text, #000);
  font-size: 35px;
  cursor: pointer;
}

.content__add-payment-card {
  .content-input_group-date-card {
    .input_group-date-card-header,
    .input_group-date-card {
      width: 100%;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, max-content) minmax(
          0,
          1fr
        );
      span {
        width: 10px;
        user-select: none;
        pointer-events: none;
        font-size: 24px;
        font-weight: 300;
        color: #ddd;
      }
      label {
        width: 100%;
        margin: 0px;
        margin-bottom: 0.25rem;
        display: block;
      }
    }
    .input_group-date-card {
      align-items: center;
      border: 1px solid #f1f1f1;
      border-radius: 8px;
      overflow: hidden;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5rem;
      input {
        border: 0;
        appearance: initial;
        -webkit-appearance: initial;
        min-width: unset;
        width: 100%;
        outline: none;
      }
    }
  }
  .ctt__logo-type-card {
    width: fit-content;
    max-width: 80px;
    height: 100%;
    position: relative;
    display: block;
    padding-left: 1rem;
    svg,
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      position: relative;
    }
  }
  .content__payment-card-double-sided {
    aspect-ratio: 1/0.55;
    position: relative;
    color: var(--color-card-payment-text, #000);
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    .content__payment-card-front,
    .content__payment-card-reverse {
      transition: transform 0.4s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      background-color: #f3f3f3;
      border-radius: 1rem;
      backface-visibility: hidden;
    }
    .content__payment-card-front {
      transform: perspective(1000px) rotateY(0deg);
    }
    .content__payment-card-reverse {
      transform: perspective(1000px) rotateY(180deg);
      .detail__card {
        flex: 1 1 0%;
        display: flex;
        align-items: center;
      }
      .first__card {
        flex: none;
      }
    }
    .first__card {
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      position: relative;
      .content__logo-type-card {
        width: 100%;
        text-align: right;
        .logo__type-card {
          width: 80px;
          height: 40px;
          display: inline-block;
          svg,
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            position: relative;
          }
        }
      }
      .content__chip-card {
        width: 100%;
        text-align: left;
        svg,
        img {
          display: inline-block;
          width: 54px;
          opacity: 0.7;
        }
      }
      .number__card {
        padding: 0.8rem 0;
        font-size: 25px;
        font-weight: 600;
        text-align: left;
        width: 100%;
        line-height: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &.placeholder__number {
          color: #ddd;
          font-weight: 400;
        }
      }
    }
    .detail__card {
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, max-content);
      align-items: center;
      .name__card {
        padding-right: 1rem;
        text-align: left;
        width: 100%;
        span {
          font-size: 12px;
        }
        p {
          font-size: 17px;
          font-weight: 500;
          line-height: normal;
          &.placeholder__name {
            color: #ddd;
            font-weight: 400;
          }
        }
      }
      .date__card {
        span,
        abbr {
          font-size: 12px;
        }
        p {
          font-size: 17px;
          font-weight: 500;
          line-height: normal;
        }
        .placeholder__date {
          color: #ddd;
          font-weight: 400;
        }
      }
      .cvv__card {
        width: calc(100% + (1.5rem * 2));
        text-align: right;
        background-color: rgba(0, 0, 0, 0.059);
        padding: 0;
        margin: 0 -1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
          font-size: 20px;
          font-weight: 600;
          line-height: normal;
          width: auto;
          display: inline-block;
          padding: 0.8rem 1rem;
          background-color: rgba(0, 0, 0, 0.071);
          min-height: 50px;
          min-width: 67px;
          &.placeholder__sc {
            color: #ddd;
          }
        }
      }
    }
    &.reversed__card {
      .content__payment-card-front {
        transform: perspective(1000px) rotateY(-180deg);
      }
      .content__payment-card-reverse {
        transform: perspective(1000px) rotateY(0deg);
      }
    }
  }
}

.delete__payment-card {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all ease-in-out 0.25s;
}

.content__info-renov {
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: rgba(var(--rgb-info-color), 0.4);
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, 1fr) minmax(
      0,
      max-content
    );
  color: var(--second-color);
  .icon__info {
    font-size: 34px;
  }
  .content__info-info {
    width: 100%;
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    h4 {
      font-size: 18px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
    }
  }
  .content__info-btn {
    align-self: center;
  }
}

.resp__table {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  table:not(.custom-table-design):not(.mat-calendar-table) {
    min-width: 100%;
    th {
      width: max-content;
      white-space: nowrap;
    }
  }
}

.content__match-event {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  gap: 1rem;
  background-color: #f9fafb;
  border: 1px solid #f2f4f7;
  position: relative;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  &.type__2 {
    grid-template-columns: minmax(0, 140px) minmax(0, 1fr);
    .match__event-detail-img {
      text-align: center;
      h5 {
        margin-bottom: 10px;
      }
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        display: block;
        margin: 0 auto;
      }
    }
    .match__event-detail {
      .match__event-name {
        h4 {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }
  }
  h4,
  h5 {
    font-family: var(--font-title);
    color: var(--second-color);
    text-transform: uppercase;
  }
  h5 {
    font-size: 15px;
    line-height: normal;
  }
  .match__vs-tournament-detail {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    .match__tournament {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
        object-fit: contain;
        object-position: center;
      }
    }
    .match__date {
      flex: 1;
      text-align: right;
    }
  }
  .match__vs-img {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, max-content));
    align-items: center;
    gap: 10px;
    justify-content: center;
    .match__team-a,
    .match__team-b {
      width: 50px;
      height: 50px;
      img,
      svg {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        object-position: center;
        filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
          drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
      }
    }
    .match__team-vs {
      width: 33px;
      height: 33px;
      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        object-position: center;
      }
    }
  }
  .match__vs-detail {
    display: flex;
    flex-direction: column;
    .match__vs-name {
      h4 {
        font-size: 18px;
        font-weight: 500;
        span.match__team-vs {
          font-weight: 400;
          color: var(--primary-color);
          text-transform: none;
        }
      }
    }
  }
}

.content__ticketing {
  .title__ticketing {
    font-family: var(--font-title);
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: var(--second-color);
    text-transform: uppercase;
  }
  .cart__countdown {
    text-align: center;
    h5 {
      font-family: var(--font-title);
      font-weight: 600;
      font-size: 36px;
      letter-spacing: -0.02em;
      color: var(--third-color);
      .cart__countdown-info {
        display: inline-block;
        margin-left: 5px;
        transform: translateY(12px);
      }
    }
  }
  .cart__title-ticketing {
    color: #475467;
    position: relative;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #eaecf0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto 0;
    }
    span {
      position: relative;
      z-index: 2;
      background-color: #fff;
      display: inline-block;
      padding: 0 5px;
    }
  }
  .cart__ticketing {
    width: 100%;
    display: block;
    padding: 1rem;
    .no__data-cart {
      width: 100%;
      padding: 3rem 0;
      text-align: center;
      .no__data-cart-circle-icon {
        width: 105px;
        display: block;
        position: relative;
        margin: 0 auto;
        background: linear-gradient(180deg, #f9fafb 0%, #edf0f3 100%);
        border-radius: 50%;
        &::before {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 40px;
          height: 40px;
          object-fit: contain;
          object-position: center;
        }
      }
      .no__data-cart-title {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
      }
      .no__data-cart-desc {
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        line-height: normal;
      }
    }
  }
  .cart__item {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, max-content) minmax(0, 1fr) minmax(
        0,
        max-content
      );
    align-items: center;
    padding: 1rem 1.5rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 0.6rem;
    .cart__item-image {
      width: 50px;
      position: relative;
      svg,
      img {
        max-width: 100%;
        display: block;
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
      .cart__item-delete {
        position: absolute;
        top: 8px;
        left: 0;
        transform: translate(-50%, -50%) scale(0.8);
      }
    }
    .cart__item-detail {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      h4 {
        font-size: 18px;
        font-family: var(--font-title);
        font-weight: 600;
        text-transform: uppercase;
        color: var(--primary-color);
        line-height: normal;
      }
      h5 {
        font-size: 16px;
        font-family: var(--font-title);
        text-transform: uppercase;
        color: var(--second-color);
        line-height: normal;
      }
    }
    .cart__item-quantity {
      width: auto;
      color: var(--second-color);
      font-size: 16px;
      font-family: var(--font-title);
      text-transform: uppercase;
      .cart__item-quantity-x {
        color: var(--primary-color);
        text-transform: none;
      }
    }
  }
  .cart__total {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f7f7f7;
    text-align: right;
    h5 {
      font-size: 16px;
      font-family: var(--font-title);
      text-transform: uppercase;
      color: var(--second-color);
      line-height: normal;
    }
  }
}

.content__ticket {
  background: linear-gradient(0deg, #f2f4f7, #f2f4f7),
    linear-gradient(0deg, #f9fafb, #f9fafb);
  border: 1px solid #f2f4f7;
  position: relative;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  .sector__img {
    width: 70px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    padding: 1rem 0;
  }
  h5 {
    font-size: 18px;
    font-family: var(--font-title);
    text-transform: uppercase;
    color: var(--second-color);
  }
  h4 {
    font-size: 18px;
    font-family: var(--font-title);
    font-weight: 600;
    text-transform: uppercase;
    color: var(--primary-color);
  }
  p {
    font-size: 12px;
    color: #667085;
    font-weight: 400;
  }
}
.content__ticket-btns {
  margin: 10px 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
  .btn__minus,
  .btn__plus,
  input {
    width: 100%;
    appearance: initial;
    position: relative;
  }
  .btn__minus,
  .btn__plus {
    font-size: 20px;
    padding: 1rem;
    text-align: center;
    line-height: normal;
    b {
      font-weight: 500;
    }
  }
  input {
    padding: 1rem;
    text-align: center;
    border: 0;
    border-left: 1px solid #d0d5dd;
    border-right: 1px solid #d0d5dd;
    color: var(--second-color);
    font-weight: 600;
  }
}

.sidebar__acquire {
  position: sticky;
  top: 0;
}

.ctt__checkout-divisor {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
}

.loader__spin3 {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: var(--second-color) var(--second-color) transparent transparent;
  box-sizing: border-box;
  animation: rotationSpin3 1s linear infinite;
}
.loader__spin3::after,
.loader__spin3::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent var(--primary-color)
    var(--primary-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBackSpin3 0.5s linear infinite;
  transform-origin: center center;
}
.loader__spin3::before {
  width: 32px;
  height: 32px;
  border-color: var(--second-color) var(--second-color) transparent transparent;
  animation: rotationSpin3 1.5s linear infinite;
}

@keyframes rotationSpin3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBackSpin3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.content__tickets-by-sector-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.content__shop {
  width: 100%;
  display: block;
  position: relative;
  .content__shop-events {
    .shop__events-item {
      overflow: hidden;
      background-image: url("/assets/img/general/bg-tickets.png");
      background-position: center;
      background-size: cover;
      margin-bottom: 2rem;
      padding: 0;
      width: 100%;
      display: grid;
      grid-template-columns: minmax(0, max-content) minmax(0, 1fr);
      background-color: black;
      .shop__events-item-event-detail-btns {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin-top: 2rem;
        .buy__tickets.cy__btn.mdc-button {
          text-transform: uppercase;
          font-family: var(--font-title);
          .mdc-button__label {
            font-size: 1.2rem;
          }
        }
      }
      .shop__events-item-event-detail {
        padding: 1rem 1.5rem;
        color: white;
        h3 {
          font-family: "Anton", sans-serif;
          font-size: 70px;
          line-height: 1.2;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
        h5 {
          font-family: var(--font-title);
          font-size: 45px;
          text-transform: uppercase;
          line-height: 1.2;
          letter-spacing: -2px;
          margin-bottom: 2rem;
        }
        p {
          // font-family: 'Cairo', sans-serif;
          font-family: "Oswald", sans-serif;
          font-size: 28px;
          line-height: normal;
          color: white;
          text-transform: uppercase;
          letter-spacing: -0.5px;
          max-width: 100%;
        }
      }
      .shop__events-item-event-img {
        position: relative;
        width: 365px;
        max-width: 100%;
        &.center__img {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &.type__match {
        .shop__events-item-event-img {
          padding: 1rem;
          align-self: center;
          .team-a {
            text-align: left;
          }
          .team-b {
            text-align: right;
          }
          .team-a,
          .team-b {
            width: 100%;
            img {
              width: 150px;
              max-width: 100%;
              display: inline-block;
              aspect-ratio: 1;
              object-fit: scale-down;
              object-position: center;
            }
          }
          .team-vs {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60px;
            z-index: 1;
          }
        }
      }
      &.type__event-img {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        position: relative;
        .shop__events-item-event-detail-btns {
          position: absolute;
          bottom: 14%;
          right: 1.5%;
        }
      }
    }
  }
}

.img__bg-nf {
  background-image: url("/assets/img/figure.svg");
}

.title__page {
  font-family: var(--font-title);
  font-size: 45px;
  color: var(--second-color);
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 0 2rem 0;
  line-height: normal;
}

.msg-confir p {
  text-align: center;
}

.content__categories {
  .content__category {
    width: 100%;
    position: relative;
    border-radius: 10px;
    background-color: white;
    aspect-ratio: 1 / 0.5;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    margin: 10px 0;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.078);
    cursor: pointer;
    transition: all ease-in-out 0.25s;
    user-select: none;
    --size-img-category: 150px;
    &:hover {
      box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.35);
    }
    .content__category-name {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: var(--primary-color);
      background: linear-gradient(
        90deg,
        var(--primary-color) 0%,
        rgba(var(--rgb-primary-color), 0.7) 20%,
        rgba(var(--rgb-primary-color), 0.2) 62%,
        rgba(255, 255, 255, 0) 100%
      );
      color: white;
      display: flex;
      align-items: center;
      h5 {
        width: calc(100% - var(--size-img-category));
        text-align: center;
        padding: 10px;
        display: block;
        line-height: normal;
        margin: 0;
        font-size: 32px;
        font-family: var(--font-title);
        text-shadow: 2px 2px 8px var(--primary-color);
      }
    }
    .content__category-img {
      width: var(--size-img-category);
      aspect-ratio: 1 / 1;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: scale-down;
        object-position: center;
      }
    }
  }
}

.content__products {
  .content__product {
    margin: 10px;
    position: relative;
    display: block;
    background-color: white;
    border-radius: 16px;
    padding: 1rem;
    .content__product-img {
      width: 100%;
      position: relative;
      aspect-ratio: 1 / 1;
      overflow: hidden;
      background-color: #f6f6f6;
      border-radius: 10px;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        object-position: center;
      }
    }
    .content__product-detail {
      padding-top: 1rem;
      .content__product-detail-name,
      .content__product-detail-price {
        font-weight: 500;
        line-height: normal;
        display: block;
        margin: 0;
      }
      .content__product-detail-name {
        font-size: 16px;
      }
      .content__product-detail-price {
        font-size: 18px;
        font-weight: 600;
        margin: 10px 0;
      }
    }
  }
}

.slide-space__down {
  &::part(container) {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
  }
  .content__image {
    width: 100%;
    aspect-ratio: 1 / 0.6;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

swiper-container {
  --swiper-pagination-color: var(--primary-color);
}

.content__product-view {
  display: flex;
  flex-wrap: wrap;
  .content__product-img {
    width: 600px;
    max-width: 100%;
    img {
      display: block;
      width: auto;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .content__product-detail {
    flex: 1;
    padding: 1rem;
    .content__product-detail-name {
      font-size: 26px;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 10px;
    }
    .content__product-detail-id {
      font-size: 16px;
      font-weight: 400;
      color: #4b4b4b;
      margin: 0 0 15px;
      line-height: normal;
      display: block;
    }
    .content__product-detail-section {
      width: 100%;
      padding: 0.5rem;
      margin-bottom: 10px;
      &.type__card {
        padding: 1rem 1.5rem;
        border: 1px solid #ddd;
        border-radius: 18px;
      }
      .content__product-detail-section-title {
        font-size: 20px;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 8px -0.5rem;
        color: var(--second-color);
      }
      p,
      .content__product-detail-decription {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 8px;
      }
    }
    .content__product-detail-price {
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      span {
        color: var(--primary-color);
        vertical-align: middle;
      }
      del {
        color: #8b8b8b;
        font-size: 80%;
        vertical-align: middle;
      }
      .badge {
        display: inline-block;
        padding: 6px 10px;
        background-color: var(--primary-color);
        color: white;
        border-radius: 8px;
        margin: 2px 10px;
      }
    }
    .content__product-detail-sizes {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .content__product-detail-size {
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 6px 15px;
        line-height: normal;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: all ease 0.26s;
        margin: 5px;
        user-select: none;
        &:hover {
          border-color: var(--primary-color);
          background-color: var(--primary-color);
          color: white;
        }
        &:has(:checked) {
          border-color: var(--primary-color);
          background-color: var(--primary-color);
          color: white;
        }
        input {
          display: none;
        }
      }
    }
    .content__product-detail-add-cart {
      display: inline-block;
      padding: 1rem;
      border: 1px solid #ddd;
      border-radius: 18px;
      .content__product-detail-add-cart-body {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        .content__product-detail-add-cart-body-quantity {
          display: inline-flex;
          flex-wrap: wrap;
          align-items: center;
        }
        h5 {
          margin: 5px 10px;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          color: var(--second-color);
        }
        input {
          width: 60px;
          margin: 0 8px;
          appearance: none;
          display: inline-block;
          min-width: unset;
          border: 1px solid #ddd;
          border-radius: 8px;
          text-align: center;
          padding: 8px;
        }
      }
    }
  }
}

.cart__btn-content {
  position: relative;
  .quantity {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--primary-color);
    color: white;
    border-radius: 3rem;
    line-height: normal;
    display: block;
    padding: 2px 6px;
    z-index: 2;
    font-size: 12px;
    min-width: 19px;
    height: 19px;
    text-align: center;
    pointer-events: none;
  }
  .cart__btn {
    padding: 0.75rem 1rem;
    min-width: unset;
  }
}

.cart__content {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  width: 400px;
  height: 100%;
  background-color: white;
  max-width: 100%;
  transform: translateX(100%);
  transition: transform ease-in-out 0.3s;
  border-left: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  &.active {
    transform: translateX(0%);
  }
  .cart__content-header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
    }
  }
  .cart__content-footer {
    width: 100%;
    padding: 1rem;
  }
  .cart__ticketing-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    flex: 1;
  }
}

.skeleton_text {
  width: 100%;
  height: 18px;
  background-color: #f1f1f1;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shine-lines 1.6s infinite linear;
  }
}

@keyframes shine-lines {
  100% {
    transform: translateX(100%);
  }
}

.content__benefits {
  display: block;
  width: 100%;
  .content__benefits-details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    .content__benefits-wrapper {
      flex: 1;
      h5 {
        text-transform: uppercase;
        font-family: var(--font-title);
        font-size: 1.4rem;
        line-height: normal;
        color: var(--primary-color);
      }
      ul {
        padding: 1rem 0 1rem 32px;
        margin: 0;
        display: block;
        list-style: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="0.8rem" height="0.8rem" stroke-width="4.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg>');
        p {
          font-size: 16px;
          color: #475467;
          margin: 0;
          line-height: 22px;
          text-align: justify;
        }
      }
    }
    .content__benefits-data {
      padding: 1rem;
      width: 350px;
      img {
        width: auto;
        max-width: 100%;
        display: block;
        margin: 0 auto;
      }
      .content__benefits-data-detail {
        width: 350px;
        max-width: 100%;
        margin: 0 auto;
        padding: 1.5rem;
        border: 1px solid #ddd;
        border-radius: 1rem;
        h4 {
          font-size: 1.4rem;
          font-weight: 600;
          color: black;
        }
        h6 {
          font-size: 0.8rem;
          font-weight: 500;
          line-height: normal;
          color: var(--second-color);
        }
      }
    }
  }
}

.content__benefits-categories {
  width: 100%;
  display: block;
  .content__benefits-categories-steps-header {
    width: 100%;
    display: block;
    position: relative;
    padding: 3rem 1rem;
    &::before {
      content: "";
      background-color: white;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 65%;
      border-radius: 1rem;
    }
    .content__benefits-categories-title {
      position: relative;
      // padding-bottom: var(--size-top-card, 5rem);
    }
    .content__benefits-categories-steps {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      align-items: flex-end;
      justify-content: center;
      .content__benefits-categories-step {
        // flex: 1 0 300px;
        user-select: none;
        max-width: calc(100% - (10px * 2));
        margin: var(--size-top-card, 5rem) 10px 0;
        width: 300px;
        button {
          display: block;
          width: 100%;
          padding: 1rem;
          border: 0;
          background: var(--primary-color);
          background-color: rgba(var(--rgb-primary-color), 0.6);
          color: white;
          border-radius: 1.2rem;
          appearance: none;
          cursor: pointer;
          position: relative;
          transition: all ease-in-out 0.252s;
          &:hover,
          &.active {
            background-color: var(--primary-color);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.078);
          }
          &.active::after {
            opacity: 1;
          }
          img {
            width: 150px;
            margin: 0 auto;
            margin-top: calc(-1 * var(--size-top-card, 5rem));
            aspect-ratio: 1 / 1;
            object-fit: scale-down;
            object-position: center;
            position: relative;
          }
          span {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            margin: 1rem 0 0;
          }
          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, 0%);
            border-width: 20px;
            border-style: solid;
            border-color: var(--primary-color) transparent transparent
              transparent;
            opacity: 0;
            transition: all ease-in-out 0.252s;
          }
        }
      }
    }
  }
  .content__benefits-categories-steps-body {
    width: 100%;
    display: block;
    .title__category {
      font-size: 26px;
      font-weight: 600;
      display: block;
      width: 100%;
      text-align: center;
      line-height: normal;
      margin: 0 0 1rem;
      color: var(--primary-color);
    }
    .content__benefits-categories-step-body {
      width: 100%;
      display: block;
      position: relative;
      padding: 1.5rem;
      background-color: white;
      border-radius: 1rem;
      .stepb__icon {
        font-size: 4rem;
        display: block;
        margin: 0 0 1rem;
        color: var(--second-color);
        svg {
          width: 1em;
          height: 1em;
          margin: 0 auto;
        }
      }
      h3 {
        font-size: 26px;
        font-weight: 600;
        display: block;
        width: 100%;
        text-align: center;
        line-height: normal;
        margin: 0 0 1rem;
        color: var(--primary-color);
      }
      h4 {
        font-size: 22px;
        font-weight: 600;
        display: block;
        width: 100%;
        text-align: center;
        line-height: normal;
        margin: 0 0 1rem;
        color: var(--primary-color);
      }
      h5 {
        font-size: 18px;
        font-weight: 600;
        display: block;
        width: 100%;
        text-align: center;
        line-height: normal;
        margin: 0 0 0.5rem;
        color: var(--second-color);
      }
      p,
      .desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: justify;
        display: block;
        width: 100%;
      }

      .content__product-cmr-points {
        width: 100%;
        position: relative;
        padding: 1rem;
        display: block;
        border: 1px solid #ddd;
        border-radius: 1rem;
        .content__product-cmr-points-image {
          width: 100%;
          aspect-ratio: 1 / 1;
          border-radius: 0.8rem;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            object-position: center;
          }
        }
        .content__product-cmr-points-detail {
          width: 100%;
          display: block;
          padding: 1rem 0;
          h5,
          p {
            text-align: left;
          }
        }
      }
      .content__product-unique-discounts {
        width: 100%;
        position: relative;
        padding: 1rem;
        display: block;
        border: 1px solid #ddd;
        border-radius: 1rem;
        cursor: pointer;
        .content__product-unique-discounts-image {
          width: 100%;
          aspect-ratio: 1 / 1;
          border-radius: 0.8rem;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: scale-down;
            object-position: center;
          }
          .content__product-unique-discounts-discount {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            background-color: var(--second-color);
            color: white;
            line-height: normal;
            padding: 12px 20px;
            border-radius: 2rem 0 0 0;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.251);
          }
        }
        .content__product-unique-discounts-detail {
          width: 100%;
          display: block;
          padding: 1rem 0;
          h6 {
            font-size: 12px;
            line-height: normal;
            margin-bottom: 5px;
            color: rgba(0, 0, 0, 0.729);
            font-weight: 500;
          }
          h5,
          p {
            text-align: left;
          }
          h5 {
            color: var(--primary-color);
          }
          p {
            min-height: 48px;
            max-height: 48px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            margin-bottom: 8px;
          }
        }
      }
    }
    .content__benefits-categories-step-body-grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      width: calc(100% + (10px * 2));
      margin: 0 -10px;
      .content__benefits-categories-step-body {
        flex: 1 0 300px;
        max-width: calc(100% - (10px * 2));
        margin: 20px 10px;
      }
    }
    .content__benefits-categories-step-body-detail {
      margin: 1rem 0 2rem;
      .content__benefits-categories-step-body-detail-card:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
    .content__benefits-categories-step-body-detail-card {
      display: flex;
      padding: 0.8rem 0.5rem;
      border: 1px solid #ddd;
      border-radius: 1rem;
      &.success {
        .content__benefits-categories-step-body-detail-card-check {
          color: var(--success-color);
        }
      }
      &.error {
        .content__benefits-categories-step-body-detail-card-check {
          color: var(--error-color);
        }
      }
      .content__benefits-categories-step-body-detail-card-info {
        flex: 1;
        padding: 0 0.8rem;
        text-align: left;
        h5 {
          text-align: left;
          color: black;
          margin: 0;
          small {
            opacity: 0.5;
          }
        }
        .remove {
          color: black;
          background-color: rgba(var(--rgb-error-color), 0.1);
          display: inline-block;
          width: fit-content;
          line-height: normal;
          padding: 2px 8px;
          border-radius: 6px;
          border: 1px solid rgba(var(--rgb-error-color), 0.5);
          margin-top: 8px;
          font-weight: 600;
          font-size: 14px;
          span {
            color: var(--error-color);
          }
        }
        .add {
          color: black;
          background-color: rgba(var(--rgb-success-color), 0.1);
          display: inline-block;
          width: fit-content;
          line-height: normal;
          padding: 2px 8px;
          border-radius: 6px;
          border: 1px solid rgba(var(--rgb-success-color), 0.5);
          margin-top: 8px;
          font-weight: 600;
          font-size: 14px;
          span {
            color: var(--success-color);
          }
        }
        .info {
          color: black;
          background-color: rgba(var(--rgb-info-color), 0.1);
          display: inline-block;
          width: fit-content;
          line-height: normal;
          padding: 2px 8px;
          border-radius: 6px;
          border: 1px solid rgba(var(--rgb-info-color), 0.5);
          margin-top: 8px;
          font-weight: 600;
          font-size: 14px;
          span {
            color: var(--info-color);
          }
          cursor: help;
        }
      }
      .content__benefits-categories-step-body-detail-card-check,
      .content__benefits-categories-step-body-detail-card-to {
        width: 50px;
        font-size: 2rem;
        align-self: center;
        text-align: center;
        svg,
        img {
          display: inline-block;
          max-width: 100%;
        }
      }
      .content__benefits-categories-step-body-detail-card-to {
        font-size: 1.5rem;
      }
    }
  }
}

.content__product-discounts {
  width: 100%;
  display: block;
  position: relative;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
  .content__product-discounts-image {
    width: 100%;
    display: block;
    position: relative;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    transition: all ease-in-out 0.26s;
    img {
      width: 100%;
      height: 100%;
      display: block;
      margin: 0;
      object-fit: scale-down;
      object-position: center;
    }
    .content__product-discounts-images {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all ease-in-out 0.26s;
      background-color: var(--primary-color);
      border: 2px solid var(--primary-color);
      border-radius: 10px;
      img {
        object-fit: cover;
        background-color: white;
        border: 2px solid var(--primary-color);
        border-radius: 10px;
      }
    }
    .btn__view-gallery {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      aspect-ratio: 1 / 1;
      background-color: var(--primary-color);
      border-radius: 50%;
      padding: 4px;
      transition: all ease-in-out 0.26s;
      &:has(button:hover) {
        box-shadow: 0 0 60px white;
        button {
          box-shadow: 0 0 20px rgba(255, 255, 255, 0.38);
        }
      }
      button {
        appearance: none;
        width: 100%;
        aspect-ratio: 1 / 1;
        border: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        cursor: pointer;
        background-color: white;
        transition: all ease-in-out 0.26s;
      }
    }
    &:hover {
      .content__product-discounts-images {
        opacity: 1;
        visibility: visible;
      }
    }
    &:has(.count_images-1) {
      .content__product-discounts-images {
        img {
          object-fit: scale-down;
        }
      }
    }
    &:has(.count_images-2) {
      .content__product-discounts-images {
        display: flex;
        img {
          width: 50%;
        }
      }
    }
    &:has(.count_images-3) {
      .content__product-discounts-images {
        display: flex;
        flex-wrap: wrap;
        img {
          width: 50%;
          height: 50%;
        }
        .btn__view-gallery {
          width: 50%;
          height: 50%;
          bottom: 0;
          right: 0;
          transform: none;
          border-radius: 10px;
          box-shadow: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid var(--primary-color);
          background-color: white;
          button {
            width: 60px;
            border: 1px solid #ddd;
            &:hover {
              box-shadow: 0 0 40px rgba(255, 255, 255, 0.3);
            }
          }
        }
      }
    }
    &:has(.count_images-4),
    &:has(.count_images-more) {
      .content__product-discounts-images {
        display: flex;
        flex-wrap: wrap;
        img {
          width: 50%;
          height: 50%;
          display: none;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            display: block;
          }
        }
      }
    }
  }
  .content__product-discounts-detail {
    width: 100%;
    padding: 1rem;
    display: block;
    h6 {
      font-size: 14px;
      font-weight: 500;
      color: black;
      opacity: 0.7;
      margin-bottom: 5px;
    }
    h5 {
      font-size: 18px;
      font-weight: 600;
      color: black;
      line-height: 22px;
      max-height: 44px;
      height: 44px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }
    .content__product-discounts-detail-price {
      min-height: 78px;
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .price,
      .old__price {
        border: 0;
      }
    }
    .price {
      font-size: 16px;
      line-height: normal;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0.6rem 0.8rem 0;
      text-align: center;
      font-weight: 600;
      color: black;
      .discount {
        background-color: var(--second-color);
        color: white;
        display: inline-block;
        padding: 4px 10px;
        border-radius: 8px;
        margin-left: 5px;
        line-height: normal;
        font-weight: 400;
      }
    }
    .old__price {
      font-size: 16px;
      line-height: normal;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0.5rem 0.8rem 0.6rem;
      text-align: center;
      font-weight: 400;
      color: black;
      opacity: 0.6;
    }
  }
}

.galleryModal {
  --mdc-dialog-container-color: transparent;
  --mdc-dialog-container-shape: 10px;
  &.isFullScreen {
    width: 100% !important;
    height: 100% !important;
    --mdc-dialog-container-shape: 0;
  }
  .content__gallery-image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    background-color: var(--bg-gallery-images-color, black);
    .content__header-btns {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 2;
      display: flex;
      align-items: center;
      .cy__btn {
        color: white;
        background: rgba(255, 255, 255, 0.2);
        margin: 2px !important;
        padding: 0.7rem 1rem;
        width: auto;
        min-width: auto;
        min-height: auto;
        height: auto;
      }
    }
    .content__gallery-image-principal {
      flex: 1;
      overflow: hidden;
      position: relative;
      z-index: 1;
      swiper-container {
        height: 100%;
        background-color: var(--bg-gallery-images-color, black);
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          object-position: center;
          background-color: var(--bg-gallery-images-color, black);
        }
      }
    }
    .content__gallery-image-thumb {
      height: var(--size-height-thumb-gallery, 180px);
      padding: 10px;
      background-color: var(--bg-gallery-images-color, black);
      position: relative;
      z-index: 1;
      swiper-container {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          object-position: center;
          background-color: rgba(255, 255, 255, 0.149);
          border-radius: 10px;
        }
      }
    }
  }
}

.zoomImageModal {
  --mdc-dialog-container-color: transparent;
  --mdc-dialog-container-shape: 10px;
  &.isFullScreen {
    width: 100% !important;
    height: 100% !important;
    --mdc-dialog-container-shape: 0;
  }
  .content__zoom__image {
    position: relative;
    width: 100%;
    height: 100%;
    .content__zoom__image-header {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 2;
      display: flex;
      align-items: center;
      .cy__btn {
        color: white;
        background: rgba(255, 255, 255, 0.2);
        margin: 2px !important;
        padding: 0.7rem 1rem;
        width: auto;
        min-width: auto;
        min-height: auto;
        height: auto;
      }
    }

    .content__zoom__image-action {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      background: black;
      swiper-container {
        width: 100%;
        height: 100%;
      }
      .img__zoom {
        object-fit: scale-down;
      }
      .loading__img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        height: auto;
        z-index: 2;
        background-color: white;
        border-radius: 1rem;
      }
    }
  }
}

.content__detail-data {
  width: 100%;
  .content__detail-data-item {
    display: block;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 1rem;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.059);
    }
    h5 {
      font-size: 12px;
      font-weight: 500;
      color: black;
      line-height: normal;
      opacity: 0.6;
      margin: 0 0 4px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: black;
      line-height: 22px;
      padding: 0 5px;
    }
  }
}

.content__service-discount {
  display: block;
  width: 100%;
  position: relative;
  .content__service-discount-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    background: white;
    border-radius: 1rem;
    padding: 1rem;
    .content__service-discount-detail,
    .content__service-discount-image {
      flex: 1 0 50%;
      padding: 1rem;
    }
    .content__service-discount-image {
      position: relative;
      padding-left: 4rem;
      aspect-ratio: 2 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.02);
      border-radius: 1rem;
      img {
        width: auto;
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }
      .content__service-discount-discount {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        width: 100px;
        aspect-ratio: 1 / 1;
        svg {
          width: 100%;
          height: 100%;
          display: block;
          margin: 0;
        }
      }
    }
    .content__service-discount-detail {
      padding-right: 4rem;
      color: black;
      .content__service-discount-detail-categories {
        width: 100%;
        display: block;
        margin: 0.5rem 0;
        span {
          display: inline-block;
          padding: 5px 10px;
          border-radius: 10px;
          background-color: var(--primary-color);
          color: white;
          font-size: 16px;
          margin: 2px;
        }
      }
      h3 {
        font-size: 38px;
        font-weight: 700;
        line-height: normal;
        margin: 1rem 0;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .content__service-discount-more-information {
    width: 100%;
    display: block;
    h4 {
      font-size: 26px;
      font-weight: 600;
      display: block;
      width: 100%;
      text-align: center;
      line-height: normal;
      margin: 0 0 1rem;
      color: var(--primary-color);
    }
    p.subtext {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      display: block;
      margin: 0;
    }
    .content__service-discount-more-information-item {
      padding: 2rem;
      background-color: white;
      border-radius: 1rem;
      width: 100%;
      display: block;
      text-align: left;
      h5 {
        display: block;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        color: var(--primary-color);
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ddd;
      }
      .content__service-discount-more-information-item-details {
        width: 100%;
        .content__service-discount-more-information-item-detail {
          width: 100%;
          display: flex;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          span {
            width: 26px;
            height: 26px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: var(--second-color);
            color: white;
            font-size: 14px;
          }
          div {
            flex: 1;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: black;
            margin: 0;
            p {
              font-size: 16px;
              line-height: 22px;
              font-weight: 400;
              color: black;
              margin: 0 0 8px;
            }
          }
        }
      }
    }
  }
}

.content__btn-btn {
  position: relative;
  width: 100%;
  .content__btn-btn-tooltip {
    font-size: 16px;
    position: absolute;
    width: 100%;
    z-index: 2;
    background: rgba(20, 20, 20, 0.871);
    color: white;
    padding: 1rem;
    line-height: normal;
    border-radius: 10px;
    right: calc(100% + 10px);
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent rgba(20, 20, 20, 0.871);
    }
  }
}

.map__sector {
  display: block;
  margin: 0 auto;
  padding: 1rem;
  width: 900px;
  max-width: 100%;
}

.home__section-0 {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background: white;
  padding: 3rem;
  border-radius: 2rem;
  .home__section-0-image {
    flex: 1;
    padding-right: 4rem;
  }
  .home__section-0-detail {
    width: 400px;
    max-width: 100%;
    text-align: left;
    h3 {
      font-family: var(--font-title);
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
  }
}

.content__trivia {
  display: flex;
  justify-content: center;
  transition: all ease-in-out 0.25s;
  .content__trivia-group-init {
    width: 500px;
    max-width: 100%;
    margin-right: 1.5rem;
    .content__trivia-init-info {
      width: auto;
      max-width: 100%;
      display: block;
      transition: all ease-in-out 0.25s;
      &.type__small {
        transform: scale(0.8);
      }
      .question__number {
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        color: var(--second-color);
        width: fit-content;
        min-width: 50px;
        aspect-ratio: 1 / 1;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 8px;
      }
    }
    .content__trivia-init {
      width: 100%;
      aspect-ratio: 1 / 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 2rem;
      background-color: var(--second-color);
      color: white;
      border-radius: 1.5rem;
      h3 {
        font-family: var(--font-title);
        font-size: 2rem;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.6rem;
      }
      .content__trivia-init-description {
        font-size: 16px;
        font-weight: 200;
        line-height: 24px;
      }
    }
  }
  .content__trivia-group-process {
    flex: 1;
    padding: 2rem;
    background-color: white;
    color: black;
    border-radius: 1.5rem;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    width: 100%;
    @extend .animate-slide-right;
    .content__trivia-group-process-status {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 0 1rem;
      &.type__footer {
        padding: 1rem 0 0;
      }
      h4 {
        font-weight: 600;
        font-size: 18px;
        color: var(--second-color);
      }
      h5 {
        font-weight: 600;
        font-size: 16px;
        color: var(--primary-color);
      }
    }
    .content__trivia-group-process-answers-scroll {
      flex-grow: 1;
      max-height: 100%;
      overflow: auto;
      @extend .content__scroll-sidebar;
    }
    .content__trivia-group-process-answers {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      .content__trivia-group-process-answers-item {
        margin: 2px 5px;
        padding: 0.4rem 1rem 0.4rem 0.4rem;
        width: calc(100% - 10px);
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 1rem;
        overflow: hidden;
        border-bottom: 1px solid #f2f2f2;
        cursor: pointer;
        transition: all ease-in-out 0.22s;
        &:hover {
          background: rgba(0, 0, 0, 0.02);
        }
        &.checked {
          background: var(--second-color);
          color: white;
        }
        span {
          min-width: 45px;
          aspect-ratio: 1 / 1;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-right: 1rem;
          background: var(--primary-color);
          color: white;
          border-radius: 50%;
        }
        h5 {
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          margin: 0;
        }
        input {
          position: absolute;
          top: -100px;
          left: -100px;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}

.animate-slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.animate-back-in-up {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration, 0.6s);
  animation-duration: var(--animate-duration, 0.6s);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: backInUp;
  animation-name: backInUp;
}

@-webkit-keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.acordion-custom .mat-expansion-panel-content {
  background-color: transparent !important;
}

.grid-video {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  align-items: center;
  gap: 16px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 240px;
    display: block;
    max-width: 100%;
  }
}


.content__iframe-events {
  border: 0;
  padding: 0;
  // max-width: 100%;
  width: calc(100% + (15px * 2));
  margin: 0 -15px;
}